exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CookieBar_cookiebar{background-color:var(--theme-cookiebar_BackgroundColor,#f7f7f7)}.CookieBar_content{align-items:center;display:flex;flex-wrap:wrap;justify-content:center;line-height:1.5;padding:5px 10% 5px 5px;position:relative;text-align:center;width:100%}.CookieBar_description{color:var(--theme-cookiebar_CookiesPolicy_Color,grey);font-size:var(--theme-cookiebar_CookiesPolicy_Size,13px)}.CookieBar_holder{display:flex;flex-wrap:wrap;justify-content:center}.CookieBar_btn-wrapper{padding:.1em .5em}.CookieBar_btn-accept-policy,.CookieBar_btn-more-info{vertical-align:bottom}.CookieBar_btn-close{background-color:transparent;border:none;cursor:pointer;font-size:22px;height:100%;line-height:1em;outline:none;position:absolute;right:0;top:0;width:10%}.CookieBar_btn-close img{max-height:18px;max-width:18px}.CookieBar_btn-close svg{height:18px;width:18px}.CookieBar_btn-close:focus{outline-offset:-1px}", ""]);

// exports
exports.locals = {
	"cookiebar": "CookieBar_cookiebar",
	"content": "CookieBar_content",
	"description": "CookieBar_description",
	"holder": "CookieBar_holder",
	"btn-wrapper": "CookieBar_btn-wrapper",
	"btnWrapper": "CookieBar_btn-wrapper",
	"btn-accept-policy": "CookieBar_btn-accept-policy",
	"btnAcceptPolicy": "CookieBar_btn-accept-policy",
	"btn-more-info": "CookieBar_btn-more-info",
	"btnMoreInfo": "CookieBar_btn-more-info",
	"btn-close": "CookieBar_btn-close",
	"btnClose": "CookieBar_btn-close"
};